import Container_RectangleAreaComponent from '../components/Container/viewer/skinComps/RectangleArea/RectangleArea.skin';


const Container_RectangleArea = {
  component: Container_RectangleAreaComponent
};


export const components = {
  ['Container_RectangleArea']: Container_RectangleArea
};

